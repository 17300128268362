<template>
  <!-- modal -->
  <b-modal
    ref="modal"
    v-model="toggleModal"
    centered
    no-close-on-backdrop
    no-close-on-esc
    size="lg"
  >
    <b-table responsive class="mb-0 custom_scroll_table" :items="items" />
  </b-modal>
</template>

<script>
import { BTable, BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BModal,
    BTable,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      toggleModal: false,
    };
  },

  methods: {
    show() {
      this.toggleModal = true;
    },
  },
};
</script>

<style lang="scss">
.custom_scroll_table {
  max-height: 50vh;
  thead {
    th {
      position: sticky;
      top: 0;
    }
  }
}
</style>
