<template>
  <div>
    <table-modal ref="modal" :items="newItems" />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search by activity name or module accessed"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        v-if="!contentLoading"
        ref="refUserListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.user.firstName)"
                :variant="
                  `light-${resolveUserRoleVariant(data.item.user.roleId)}`
                "
              />
            </template>
            {{ data.item.user.firstName }} {{ data.item.user.lastName }}
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.user.roleId)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.user.roleId)}`"
            />
            <span class="align-text-top text-capitalize">{{
              resolveUserRoleName(data.item.user.roleId)
            }}</span>
          </div>
        </template>

        <template #cell(moduleAccessed)="data">
          {{ data.item.moduleName }}
        </template>

        <template #cell(Value)="data">
          <feather-icon
            v-if="data.item.previousValue && data.item.currentValue"
            style="cursor: pointer"
            icon="AlignJustifyIcon"
            size="16"
            class="align-middle text-body openValueModal"
            @click="
              openValueModal(data.item.previousValue, data.item.currentValue)
            "
          />
        </template>

        <template #cell(Date&Time)="data">
          <b-badge pill class="text-capitalize">
            {{ data.item.createdDate | formatDateTime }}
          </b-badge>
        </template>
      </b-table>
      <div v-if="contentLoading">
        <Loader />
      </div>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing  of {{ totalUsers }} entries</span> -->
            <span class="text-muted">Showing {{ totalLogs >= 1 ? startIndex + 1 : startIndex }} to
              {{ endIndex > totalLogs ? totalLogs : endIndex }} of
              {{ totalLogs }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalLogs"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="pageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BPagination,
  BBadge,
  BMedia,
  BAvatar
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";

import vSelect from "vue-select";
import {
  perPageOptions,
  perPage,
  currentPage,
  resolveUserRoleVariant,
  resolveUserRoleName,
  resolveUserRoleIcon,
  resConditionCheck,
  RoleEnum
} from "@/helpers/constant";
import logService from "@/services/logs/logs.service";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TableModal from "@/views/common-components/TableModal.vue";
import checkLoginRole from "@/helpers/checkLoginRole";
import accessRightCheck from "@/helpers/accessRightCheck";
import * as moment from "moment";
import Loader from "@/layouts/skeloton-loader/Loader.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    vSelect,
    BBadge,
    BMedia,
    BAvatar,
    TableModal,
    Loader
  },

  data() {
    return {
      contentLoading: true,
      searchQuery: "",
      sortBy: "",
      perPageOptions,
      perPage,
      totalLogs: 0,
      isSortDirDesc: true,
      currentPage,
      items: [],
      RoleEnum,
      newItems: [],
      checkAccess: {},
      checkLoginRole,
      deleteMsg: "",
      titleMsg: "",
      startIndex: 0,
      endIndex: 0,
      errorData: "",
      avatarText,
      resolveUserRoleIcon,
      resolveUserRoleName,
      contentId: "",
      previousValue: {},
      currentValue: {},
      resolveUserRoleVariant,
      tableColumns: [
        { key: "User" },
        { key: "Role" },
        { key: "moduleAccessed" },
        { key: "activityName" },
        { key: "Value" },
        { key: "Date&Time" }
      ]
    };
  },

  watch: {
    currentPage: {
      handler() {
        this.getLogsListing();
      }
    },

    perPage: {
      handler() {
        this.currentPage = 1;
        this.getLogsListing();
      }
    },

    sortBy: {
      handler() {
        this.getCurrencyListing();
      }
    },

    searchQuery: {
      handler() {
        this.getLogsListing();
      }
    },

    isSortDirDesc: {
      handler() {
        this.getLogsListing();
      }
    }
  },

  mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
    this.getLogsListing();
  },

  methods: {
    pageChange() {
      window.scrollTo(0, 0);
    },

    async getLogsListing() {
      this.contentLoading = true
      const payload = {
        no_of_result: this.perPage,
        page: this.currentPage,
        order: this.isSortDirDesc === true ? "DESC" : "ASC",
        order_by: this.sortBy,
        search: this.searchQuery
      };
      await logService
        .getLogsListing(payload)
        .then(res => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.contentLoading = false;
            this.items = res.data.data;
            this.totalLogs = res.data.count;
            this.startIndex = this.currentPage * this.perPage - this.perPage;
            this.endIndex = this.startIndex + this.perPage;
          }
        })
        .catch(error => {
          const errorData = errorResponseHandler(error);
          this.contentLoading = false;
          if (error.response.status === 404) {
            this.items = [];
            this.totalLogs = 0;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          }
        });
    },

    openValueModal(previousValue, currentValue) {
      this.newItems = [];
      Object.keys(currentValue).forEach(key => {
        if (
          (currentValue[key] || previousValue[key])
          && currentValue[key] !== previousValue[key]
          && key !== "rights"
        ) {
          if (key === "deactivateDate") {
            // eslint-disable-next-line no-param-reassign
            previousValue[key] = previousValue[key]
              ? moment
                .utc(previousValue[key])
                .local()
                .format("YYYY-MM-DD HH:mm:ss")
              : "";
            // eslint-disable-next-line no-param-reassign
            currentValue[key] = currentValue[key]
              ? moment
                .utc(currentValue[key])
                .local()
                .format("YYYY-MM-DD HH:mm:ss")
              : "";
          }
          this.newItems.push({
            field: key,
            previousValue: previousValue[key] ? previousValue[key] : "N/A",
            currentValue: currentValue[key] ? currentValue[key] : "N/A"
          });
        }
      });
      this.$refs.modal.show();
    }
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
