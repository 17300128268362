import axios from 'axios'

function getLogsListing(payload) {
  return axios.get('activity', {
    params: payload
  })
}

export default {
  getLogsListing
}
